import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";

const CardHeader = styled.div`
  font-weight: 500;
`;

const CardFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DragItem = styled.div`
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: white;
  margin: 0 0 8px 0;
  display: grid;
  grid-gap: 20px;
  flex-direction: column;
`;

const Pin = styled.span`
  ${props => props.pinned ? 
    'border: 1px solid black;background-color: red;color: #fff;cursor: not-allowed;' :
    'border: 1px solid black;background-color: #fff;color: #000;cursor: pointer;'
  }
}`;

const ListItem = ({ item, index }) => {
  const [pinned, setIsPinned] = useState(false);

  return (
    <Draggable draggableId={item.id} index={index} isDragDisabled={pinned}>
      {(provided, snapshot) => {
        return (
          <DragItem
            ref={provided.innerRef}
            snapshot={snapshot}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <CardHeader>Job: {item.job_id}</CardHeader>
              <Pin pinned={pinned} onClick={() => {
                  setIsPinned(!pinned);
                }} >
                {pinned ? "Pin" : "Pin"}
              </Pin>
              <CardFooter>
                <span>{item.content}</span>
              </CardFooter>
          </DragItem>
        );
      }}
    </Draggable>
  );
};

export default ListItem;
