import React from "react";
import styled from "styled-components";
import { useTable, useSortBy } from "react-table";
import makeData from "./makeData";

const Styles = styled.div`
  padding: 1rem;
  min-width: 1800px;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

function Table({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  // We don't want to render all 2000 rows for this example, so cap
  // it at 20 for this use case
  const firstPageRows = rows.slice(0, 20);

  return (
    <>
      <table
        {...getTableProps()}
        style={{
          width: "2400px",
        }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
      {/* <div>Showing the first 20 results of {rows.length} rows</div> */}
    </>
  );
}

export default function GottaGrooveGrid(props) {
  //   const { currQuote } = useContext(QuoteContext);
  const columns = React.useMemo(
    () => [
      {
        Header: "Job",
        columns: [
          {
            Header: "Job Number",
            accessor: "firstName",
          },
          {
            Header: "Artist",
            accessor: "artist",
          },
          { Header: "Release", accessor: "release" },

          // { Header: "Change Order?", accessor: "lastName" },
          // { Header: "Job Number", accessor: "lastName" },
          // { Header: "Billing First Name", accessor: "lastName" },
          // { Header: "Billing Last Name", accessor: "lastName" },
          // { Header: "Matrix A", accessor: "lastName" },
          // { Header: "Matrix B", accessor: "lastName" },
          // { Header: "Open?", accessor: "lastName" },
          // { Header: "Labels Baked", accessor: "lastName" },
          // { Header: "Schedule Date", accessor: "lastName" },
          // { Header: "Done Pressing", accessor: "lastName" },
          // { Header: "Due Date", accessor: "lastName" },

          // { Header: "Lacquer Status", accessor: "lastName" },
          // { Header: "Track Order Lacquer", accessor: "lastName" },
          // { Header: "# Sides Recut", accessor: "lastName" },
          // { Header: "Lacquer Type", accessor: "lastName" },
          // { Header: "Stamper Status", accessor: "lastName" },
          // { Header: "Track Order Plating Complete", accessor: "lastName" },
          // { Header: "QA Notes", accessor: "lastName" },
          // { Header: "Assembly Notes", accessor: "lastName" },
          // { Header: "Our Notes", accessor: "lastName" },

          // { Header: "# of Inserts", accessor: "lastName" },
          // { Header: "Downloads", accessor: "lastName" },
          // { Header: "Track Order Downloads", accessor: "lastName" },
          // { Header: "Shrinkwrap Status", accessor: "lastName" },
          // { Header: "Polybags Status", accessor: "lastName" },
          // { Header: "Stickers Status", accessor: "lastName" },
          // { Header: "Track Order Stickers", accessor: "lastName" },
          // { Header: "Test Pressings Approval", accessor: "lastName" },
          // {
          //   Header: "Track Order Test Pressing Approved",
          //   accessor: "lastName",
          // },
          // { Header: "Has Customs", accessor: "lastName" },
        ],
      },
      {
        Header: "Status",
        columns: [
          {
            Header: "Labels Status",
            accessor: "labels_status",
          },
          {
            Header: "Track Order Labels",
            accessor: "track_order_labels",
          },
          {
            Header: "Jackets Status",
            accessor: "jackets_status",
          },
          {
            Header: "Track Order Jackets",
            accessor: "track_order_jackets",
          },
          {
            Header: "Sleeves Status",
            accessor: "sleeves_status",
          },
          {
            Header: "Track Order Sleeves",
            accessor: "track_order_sleeves",
          },
          {
            Header: "Inserts Status",
            accessor: "inserts_status",
          },
          {
            Header: "Track Order Inserts",
            accessor: "track_order_inserts",
          },
        ],
      },
      {
        Header: "Vinyl",
        columns: [
          {
            Header: "Vinyl Quantity",
            accessor: "age1",
          },
          {
            Header: "Vinyl Color",
            accessor: "visits1",
          },
          {
            Header: "Vinyl Size",
            accessor: "status1",
          },
          {
            Header: "Vinyl Hole Size",
            accessor: "progress1",
          },
        ],
      },

      {
        Header: "Vinyl",
        columns: [
          {
            Header: "Vinyl Quantity",
            accessor: "age",
          },
          {
            Header: "Vinyl Color",
            accessor: "visits",
          },
          {
            Header: "Vinyl Size",
            accessor: "status",
          },
          {
            Header: "Vinyl Hole Size",
            accessor: "progress",
          },
        ],
      },
    ],
    []
  );

  const data = React.useMemo(() => makeData(2000), []);

  return (
    <div {...props} className="split-pane-bottom grid-container">
      {/* Current <b>quote id</b>: {currQuote} */}
      <Styles>
        <Table columns={columns} data={data} />
      </Styles>
    </div>
  );
}
