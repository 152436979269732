import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import QuoteContext from "./QuoteContext";
import SplitPaneContext from "./SplitPaneContext";
import styled from "styled-components";

import { DragDropContext, Droppable } from "react-beautiful-dnd";

import ListItem from "./ListItem";
import DraggableElement from "./DraggableElement";

// Configuration
const numberOfShifts = 3;
const daysToDisplay = 2;

// Presses
const presses = [
  {
    id: 1,
    name: "Press 1",
    details: '12"',
  },
  {
    id: 2,
    name: "Press 2",
    details: '12"',
  },
  {
    id: 3,
    name: "Press 3",
    details: "180 gram",
  },
  {
    id: 4,
    name: "Press 4",
    details: "7 inch",
  },
  {
    id: 5,
    name: "Press 5",
    details: '12"',
  },
  {
    id: 6,
    name: "Press 6",
    details: '12"',
  },
  {
    id: 7,
    name: "Press 7",
    details: '12"',
  },
  {
    id: 8,
    name: "Press 8",
    details: '12"',
  },
];

const job_cards = [
  {
    id: "21-0100",
    job_id: "21-0100",
    prefix: "First Album",
    content: "FEB 2022",
    press: 2,
    date: "2021-11-23",
    shift: 2,
  },
  {
    id: "21-0101",
    job_id: "21-0101",
    prefix: "Second Album",
    content: "FEB 2022",
  },

  {
    id: "21-0102",
    job_id: "21-0102",
    prefix: "First Album",
    content: "FEB 2022",
  },
  {
    id: "21-0103",
    job_id: "21-0103",
    prefix: "First Album",
    content: "FEB 2022",
  },
  {
    id: "21-0104",
    job_id: "21-0104",
    prefix: "First Album",
    content: "FEB 2022",
  },
  {
    id: "21-0105",
    job_id: "21-0105",
    prefix: "First Album",
    content: "FEB 2022",
  },
];

// Dates
const future = new Date();
future.setDate(future.getDate() + daysToDisplay - 1);
var today = new Date();
var daysOfYear = [];
var options = {
  weekday: "long",
  year: "numeric",
  month: "numeric",
  day: "numeric",
};
for (today; today <= future; today.setDate(today.getDate() + 1)) {
  let theDate = new Date(today);
  daysOfYear.push({
    prettyDate: theDate.toLocaleDateString("en-US", options),
    rawDate: theDate,
    apiDateString: theDate.toISOString().split("T")[0],
  });
}

const removeFromList = (list, index) => {
  const result = Array.from(list);
  const [removed] = result.splice(index, 1);
  return [removed, result];
};

// Build out Object where keys are in form press-1-date-YYYY-MM-DD-shift-1
let pressShifts = {};
presses.forEach((item) => {
  daysOfYear.forEach((d) => {
    for (let i = 1; i <= numberOfShifts; i++) {
      pressShifts[`press-${item.id}-date-${d.apiDateString}-shift-${i}`] = {
        cards: [],
        shiftKey: `press-${item.id}-date-${d.apiDateString}-shift-${i}`,
        press: item.id,
        date: d.apiDateString,
        shift: i,
      };
    }
  });
});

// Add On-Deck Section
pressShifts["ondeck"] = {
  cards: job_cards,
};

const DroppableStyles = styled.div`
  padding: 10px;
  border-radius: 6px;
  background: #d4d4d4;
`;

export const GottaGrooveScheduleView = (props) => {
  const topRef = createRef();
  const { clientHeight, setClientHeight } = useContext(SplitPaneContext);
  const { quotes, setCurrQuote } = useContext(QuoteContext);

  const [elements, setElements] = React.useState(pressShifts);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const listCopy = { ...elements };
    const sourceList = listCopy[result.source.droppableId].cards;
    const [removedElement, remainingCardsList] = removeFromList(
      sourceList,
      result.source.index
    );

    // Remove dropped card from origin
    listCopy[result.source.droppableId].cards = remainingCardsList;

    let i = 0;

    // See whats in the destination before we override it
    let destinationShiftId = result.destination.droppableId;
    let destinationList = listCopy[destinationShiftId].cards;
    console.log("up here destinationlist", destinationList);

    listCopy[destinationShiftId].cards.push(removedElement);

    // do {
    //   console.log(
    //     "beginning of the do",
    //     destinationShiftId,
    //     destinationList,
    //     removedElement
    //   );

    //   i = i + 1;

    //   // Put removed element into the destination

    //   console.log(
    //     "just put ",
    //     removedElement,
    //     " into shift",
    //     destinationShiftId
    //   );

    //   // Look for the next
    //   const nextShift = walkToNextShift(listCopy, destinationShiftId);
    //   if (nextShift) {
    //     console.log("the next shift is", nextShift);

    //     console.log("DESTINATION", destinationShiftId, destinationList);

    //     listCopy[nextShift.shiftKey].cards = [destinationList];

    //     if (nextShift.cards.length > 0) {
    //       console.log("next shift already has a card");

    //       destinationShiftId = nextShift.shiftKey;
    //       destinationList = nextShift.cards[0];

    //       console.log("NOW IT IS", destinationShiftId, destinationList);
    //     } else {
    //       console.log("next shift is empty, drop the job here");

    //       console.log("next shift is empty");
    //     }

    //     // listCopy[destinationShiftId].cards = [destinationList];
    //   }
    // } while (i < 3 && destinationList.length !== 0);

    // while (destinationList.length !== 0) {
    //   console.log('destinationList is not empty');
    //   const nextShift = walkToNextShift(listCopy, destinationShiftId);
    //   console.log('nextShift', nextShift);
    // //   listCopy[nextShift.shiftKey].cards = [destinationList[0]];

    // }

    // // Add element to where it was dropped

    //   // Dropped to a list with a job card already in it
    // if (destinationList.length === 1) {
    //   const nextShift = walkToNextShift(listCopy, result.destination.droppableId);
    //   console.log('nextShift', nextShift);

    //   if (nextShift) {
    //     if (nextShift.cards.length > 0) {
    //       console.log('next shift has cards in it, bump');
    //     } else {
    //       // Put the next one in place
    //       listCopy[nextShift.shiftKey].cards = [destinationList[0]];
    //     }
    //   }
    // }

    console.log("what is list copy", listCopy);
    setElements(listCopy);
  };

  useEffect(() => {
    if (!clientHeight) {
      setClientHeight(topRef.current.clientHeight);
      return;
    }

    topRef.current.style.minHeight = clientHeight + "px";
    topRef.current.style.maxHeight = clientHeight + "px";
  }, [clientHeight]);

  return (
    <div {...props} className="split-pane-top" ref={topRef}>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="press-container">
          {presses.map((press) => (
            <div key={`press-${press.id}`} className="press">
              <h2>{press.name}</h2>
              {/* <h3>{press.details}</h3> */}

              <div className="date-container">
                {daysOfYear.map((date) => (
                  <div className="dates" key={`date-${date.apiDateString}`}>
                    <h3>{date.prettyDate}</h3>
                    <div className="jobs-container">
                      {Array.from(
                        { length: numberOfShifts },
                        (x, i) => i + 1
                      ).map((shift) => (
                        <div
                          className="date-drop"
                          key={`date-drop-press-${press.id}-date-${date.apiDateString}-shift-${shift}`}
                        >
                          <h4>Shift {shift}</h4>
                          <DroppableStyles>
                            {/* isDropDisabled={elements[`press-${press.id}-date-${date.apiDateString}-shift-${shift}`].length > 0} */}
                            <Droppable
                              droppableId={`press-${press.id}-date-${date.apiDateString}-shift-${shift}`}
                            >
                              {(provided) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  key={`droppbable-drop-press-${press.id}-date-${date.apiDateString}-shift-${shift}`}
                                >
                                  {provided.placeholder}
                                  {elements[
                                    `press-${press.id}-date-${date.apiDateString}-shift-${shift}`
                                  ].cards.map((item, index) => (
                                    <ListItem
                                      key={item.id}
                                      item={item}
                                      index={index}
                                    />
                                  ))}
                                </div>
                              )}
                            </Droppable>
                          </DroppableStyles>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}

          <div className="jobs-container">
            <DraggableElement
              elements={elements["ondeck"]}
              key={"ondeck"}
              prefix={"ondeck"}
            />
          </div>
        </div>
      </DragDropContext>

      {/* <ul>
          {quotes.map((el, i) => {
            return (
              <li key={i}>
                <a href="#" onClick={() => setCurrQuote(el.id)}>
                  {el.author}
                </a>
              </li>
            );
          })}
        </ul> */}
    </div>
  );
};

export default GottaGrooveScheduleView;
