import { Droppable } from "react-beautiful-dnd";
import ListItem from "./ListItem";
import React from "react";
import styled from "styled-components";

const DroppableStyles = styled.div`
  padding: 4px;
  border-radius: 6px;
  margin: 4px;
  background: #d4d4d4;
`;

const DraggableElement = ({ prefix, elements }) => {
  return (
    <DroppableStyles>
      <Droppable droppableId={`${prefix}`}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {elements.cards.map((item, index) => (
                <ListItem key={item.id} item={item} index={index} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DroppableStyles>
  );
};

export default DraggableElement;
